export const CategoryLastMinute = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="23"
    viewBox="0 0 21 23"
    fill="none"
  >
    <path
      d="M13.4209 2.4375H7.9834C7.48315 2.4375 7.07715 2.0315 7.07715 1.53125C7.07715 1.031 7.48315 0.625 7.9834 0.625H13.4209C13.9211 0.625 14.3271 1.031 14.3271 1.53125C14.3271 2.0315 13.9211 2.4375 13.4209 2.4375Z"
      fill="#CC9E96"
    />
    <path
      d="M10.7021 4.24988C5.69693 4.24988 1.63965 8.30716 1.63965 13.3124C1.63965 18.3176 5.69693 22.3749 10.7021 22.3749C15.7074 22.3749 19.7646 18.3176 19.7646 13.3124C19.7646 8.30716 15.7074 4.24988 10.7021 4.24988ZM15.2334 14.2186H10.7021C10.2019 14.2186 9.7959 13.8126 9.7959 13.3124V8.78113C9.7959 8.28088 10.2019 7.87488 10.7021 7.87488C11.2024 7.87488 11.6084 8.28088 11.6084 8.78113V12.4061H15.2334C15.7336 12.4061 16.1396 12.8121 16.1396 13.3124C16.1396 13.8126 15.7336 14.2186 15.2334 14.2186Z"
      fill="#A4766E"
    />
  </svg>
);

export const CategoryLastMinuteActive = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
  >
    <path
      d="M12.8193 2.25H7.56934C7.08634 2.25 6.69434 1.858 6.69434 1.375C6.69434 0.892 7.08634 0.5 7.56934 0.5H12.8193C13.3023 0.5 13.6943 0.892 13.6943 1.375C13.6943 1.858 13.3023 2.25 12.8193 2.25Z"
      fill="#AC75E5"
    />
    <path
      d="M10.1943 3.99988C5.36171 3.99988 1.44434 7.91725 1.44434 12.7499C1.44434 17.5825 5.36171 21.4999 10.1943 21.4999C15.027 21.4999 18.9443 17.5825 18.9443 12.7499C18.9443 7.91725 15.027 3.99988 10.1943 3.99988ZM14.5693 13.6249H10.1943C9.71134 13.6249 9.31934 13.2329 9.31934 12.7499V8.37488C9.31934 7.89188 9.71134 7.49988 10.1943 7.49988C10.6773 7.49988 11.0693 7.89188 11.0693 8.37488V11.8749H14.5693C15.0523 11.8749 15.4443 12.2669 15.4443 12.7499C15.4443 13.2329 15.0523 13.6249 14.5693 13.6249Z"
      fill="#893BD9"
    />
  </svg>
);
