const ShiningStarIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.64435 2.65211C8.71794 2.4049 9.06506 2.39676 9.15015 2.64026L10.4162 6.26307C10.4683 6.41216 10.5877 6.52787 10.7384 6.57517L14.1509 7.64644C14.3929 7.72241 14.4012 8.06185 14.1632 8.14956L10.7154 9.42022C10.5781 9.47084 10.4698 9.57911 10.4192 9.71647L9.14853 13.1643C9.06081 13.4023 8.72137 13.394 8.6454 13.152L7.57413 9.73946C7.52683 9.58878 7.41112 9.4693 7.26203 9.4172L3.63922 8.15119C3.39573 8.0661 3.40386 7.71898 3.65108 7.64539L7.2396 6.5772C7.40142 6.52904 7.528 6.40245 7.57617 6.24063L8.64435 2.65211Z"
        fill="#FFBC00"
        stroke="#FFBC00"
      />
      <path
        d="M3.13567 1.6042C3.22449 1.4088 3.50204 1.4088 3.59086 1.6042L4.17876 2.89758C4.20877 2.96362 4.2617 3.01654 4.32773 3.04655L5.62111 3.63445C5.81651 3.72327 5.81651 4.00082 5.62111 4.08964L4.32773 4.67754C4.2617 4.70755 4.20877 4.76047 4.17876 4.82651L3.59086 6.11989C3.50204 6.31529 3.22449 6.31529 3.13567 6.11989L2.54777 4.82651C2.51776 4.76047 2.46484 4.70755 2.3988 4.67754L1.10542 4.08964C0.910024 4.00082 0.910023 3.72327 1.10542 3.63445L2.3988 3.04655C2.46484 3.01654 2.51776 2.96362 2.54777 2.89758L3.13567 1.6042Z"
        fill="#FFBC00"
      />
      <path
        d="M4.6662 11.1918C4.73092 11.0495 4.93315 11.0495 4.99786 11.1918L5.37972 12.0319C5.4137 12.1067 5.47362 12.1666 5.54837 12.2006L6.38847 12.5825C6.53085 12.6472 6.53085 12.8494 6.38848 12.9141L5.54837 13.296C5.47362 13.33 5.4137 13.3899 5.37972 13.4646L4.99786 14.3047C4.93315 14.4471 4.73092 14.4471 4.6662 14.3047L4.28434 13.4646C4.25036 13.3899 4.19044 13.33 4.11569 13.296L3.27559 12.9141C3.13322 12.8494 3.13322 12.6472 3.27559 12.5825L4.11569 12.2006C4.19044 12.1666 4.25036 12.1067 4.28434 12.0319L4.6662 11.1918Z"
        fill="#FFBC00"
      />
    </svg>
  );
};

export default ShiningStarIcon;
