export default function ResetIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.168 3.33337V8.33337H14.168"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0756 12.5C16.5339 14.0332 15.5085 15.3489 14.154 16.2487C12.7995 17.1485 11.1893 17.5838 9.5659 17.4888C7.94254 17.3938 6.39401 16.7738 5.15368 15.7222C3.91335 14.6706 3.0484 13.2443 2.68919 11.6584C2.32997 10.0724 2.49595 8.41263 3.1621 6.92919C3.82826 5.44576 4.9585 4.21902 6.38251 3.43384C7.80653 2.64865 9.44716 2.34755 11.0572 2.57592C12.6672 2.80429 14.1594 3.54975 15.3089 4.69997L19.1672 8.3333"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
