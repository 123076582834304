export const YourPickBackground = (
  <svg
    width="157"
    height="32"
    viewBox="0 0 157 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="your-pick-background"
  >
    <path d="M6 0L10.7631 6.75H1.23686L6 0Z" fill="#331900" />
    <path d="M151 0L146.237 6.75H155.763L151 0Z" fill="#331900" />
    <g filter="url(#filter0_d_16786_193259)">
      <mask id="path-3-inside-1_16786_193259" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M151 0H6L6.72864 5.25H6.72949L9.01985 21.1412C9.58724 25.078 12.9606 28 16.938 28H139.117C142.922 28 146.2 25.3198 146.957 21.5906L150.272 5.25H150.272L151 0Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151 0H6L6.72864 5.25H6.72949L9.01985 21.1412C9.58724 25.078 12.9606 28 16.938 28H139.117C142.922 28 146.2 25.3198 146.957 21.5906L150.272 5.25H150.272L151 0Z"
        fill="url(#paint0_linear_16786_193259)"
      />
      <path
        d="M6 0V-1H4.85163L5.00949 0.137471L6 0ZM151 0L151.991 0.137437L152.148 -1H151V0ZM6.72864 5.25L5.73814 5.38747L5.85785 6.25H6.72864V5.25ZM6.72949 5.25L7.71926 5.10735L7.5957 4.25H6.72949V5.25ZM9.01985 21.1412L10.0096 20.9986L9.01985 21.1412ZM146.957 21.5906L147.937 21.7895L147.937 21.7895L146.957 21.5906ZM150.272 5.25L151.252 5.44883L151.495 4.25H150.272V5.25ZM150.272 5.25L149.281 5.11256L149.123 6.25H150.272V5.25ZM6 1H151V-1H6V1ZM7.71915 5.11253L6.99051 -0.137471L5.00949 0.137471L5.73814 5.38747L7.71915 5.11253ZM6.72949 4.25H6.72864V6.25H6.72949V4.25ZM10.0096 20.9986L7.71926 5.10735L5.73972 5.39265L8.03008 21.2839L10.0096 20.9986ZM16.938 27C13.4578 27 10.5061 24.4432 10.0096 20.9986L8.03008 21.2839C8.66839 25.7127 12.4634 29 16.938 29V27ZM139.117 27H16.938V29H139.117V27ZM145.977 21.3918C145.315 24.6548 142.446 27 139.117 27V29C143.397 29 147.086 25.9848 147.937 21.7895L145.977 21.3918ZM149.292 5.05117L145.977 21.3918L147.937 21.7895L151.252 5.44883L149.292 5.05117ZM150.272 6.25H150.272V4.25H150.272V6.25ZM150.009 -0.137437L149.281 5.11256L151.262 5.38744L151.991 0.137437L150.009 -0.137437Z"
        fill="url(#paint1_linear_16786_193259)"
        mask="url(#path-3-inside-1_16786_193259)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_16786_193259"
        x="4"
        y="0"
        width="149"
        height="32"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_16786_193259"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_16786_193259"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_16786_193259"
        x1="2.28205"
        y1="4.4"
        x2="143.279"
        y2="57.0004"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A57E22" />
        <stop offset="0.246002" stopColor="#EBC755" />
        <stop offset="0.513389" stopColor="#CDAD3C" />
        <stop offset="0.751898" stopColor="#C6A53D" />
        <stop offset="1" stopColor="#A17E1A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_16786_193259"
        x1="78.5"
        y1="0"
        x2="78.5"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B2902B" stopOpacity="0" />
        <stop offset="1" stopColor="#FBD558" />
      </linearGradient>
    </defs>
  </svg>
);

export const YourPickStar = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
  >
    <g filter="url(#filter0_d_16786_196164)">
      <path
        d="M6.61687 0.75623C6.79648 0.203443 7.57852 0.203444 7.75813 0.75623L8.62439 3.42229C8.70471 3.6695 8.93509 3.83688 9.19502 3.83688H11.9983C12.5795 3.83688 12.8212 4.58065 12.351 4.92229L10.0831 6.57001C9.87278 6.72279 9.78478 6.99361 9.86511 7.24083L10.7314 9.90689C10.911 10.4597 10.2783 10.9193 9.80806 10.5777L7.54017 8.92999C7.32988 8.77721 7.04512 8.77721 6.83483 8.92999L4.56694 10.5777C4.09671 10.9193 3.46403 10.4597 3.64364 9.90689L4.50989 7.24083C4.59022 6.99361 4.50222 6.72279 4.29193 6.57001L2.02404 4.92229C1.55381 4.58065 1.79548 3.83688 2.37671 3.83688H5.17998C5.43991 3.83688 5.67029 3.6695 5.75061 3.42229L6.61687 0.75623Z"
        fill="#40330D"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_16786_196164"
        x="0.775391"
        y="0.341614"
        width="12.8242"
        height="12.3534"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.85672 0 0 0 0 0.389176 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_16786_196164"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_16786_196164"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
