export const CategoryMusicals = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="47"
    viewBox="0 0 40 47"
    fill="none"
  >
    <g clipPath="url(#clip0_13561_1012)">
      <path
        d="M7.12233 24.1282V28.3836V35.5687C6.71513 35.4719 6.28942 35.4174 5.85138 35.4174C2.85908 35.4174 0.428223 37.7963 0.428223 40.7382C0.428223 43.6801 2.85291 46.059 5.85138 46.059C8.67092 46.059 10.9846 43.9464 11.2437 41.2467H11.2684V28.6499L25.4771 23.656V30.7383C24.891 30.5627 24.2679 30.4659 23.6201 30.4659C20.0787 30.4659 17.2036 33.2867 17.2036 36.7612C17.2036 40.2358 20.0787 43.0566 23.6201 43.0566C27.1615 43.0566 29.9563 40.3084 30.0304 36.8944H30.0365V16.0774L30.0612 10.1089L12.8355 16.1621C9.41129 17.3667 7.12233 20.5507 7.12233 24.1282Z"
        fill="#FF9D7C"
      />
      <path
        d="M35.7137 12.6576C34.998 12.6576 34.3317 12.8513 33.7579 13.1782L34.0849 8.04503C34.1836 5.98693 33.2026 3.55959 30.5311 2.2642C27.8597 0.96881 26.9774 0.00634766 26.9774 0.00634766C26.9774 0.00634766 26.5702 3.89252 27.5882 5.45425C28.6062 7.01598 32.4623 9.03776 32.3574 10.2726L31.87 15.9082C31.8391 16.1019 31.8206 16.2956 31.8206 16.4953C31.8206 18.614 33.5666 20.327 35.726 20.327C37.8854 20.327 39.6314 18.614 39.6314 16.4953C39.6314 14.3767 37.8854 12.6636 35.726 12.6636L35.7137 12.6576Z"
        fill="#FFC4B0"
      />
    </g>
    <defs>
      <clipPath id="clip0_13561_1012">
        <rect
          width="39.1837"
          height="46.0408"
          fill="white"
          transform="translate(0.428223)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const CategoryMusicalsActive = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <g clipPath="url(#clip0_15107_8448)">
      <path
        d="M3.94766 10.2793V12.4889V16.2197C3.7278 16.1694 3.49794 16.1412 3.26142 16.1412C1.64577 16.1412 0.333252 17.3764 0.333252 18.9039C0.333252 20.4314 1.64243 21.6667 3.26142 21.6667C4.7838 21.6667 6.03302 20.5697 6.17294 19.1679H6.18626V12.6272L13.8581 10.0342V13.7116C13.5417 13.6204 13.2052 13.5701 12.8554 13.5701C10.9433 13.5701 9.39092 15.0348 9.39092 16.8389C9.39092 18.643 10.9433 20.1077 12.8554 20.1077C14.7676 20.1077 16.2766 18.6808 16.3166 16.9081H16.3199V6.09906L16.3333 3L7.0324 6.14307C5.18355 6.76854 3.94766 8.42179 3.94766 10.2793Z"
        fill="#8000FF"
      />
      <path
        d="M20.0258 5.31413C19.7261 5.31413 19.447 5.39039 19.2067 5.51908L19.3436 3.49823C19.385 2.68798 18.9741 1.73236 17.8553 1.22238C16.7364 0.712406 16.3669 0.333496 16.3669 0.333496C16.3669 0.333496 16.1964 1.86343 16.6227 2.47827C17.0491 3.0931 18.6641 3.88905 18.6201 4.3752L18.416 6.59385C18.4031 6.67011 18.3953 6.74636 18.3953 6.82501C18.3953 7.65908 19.1266 8.3335 20.031 8.3335C20.9353 8.3335 21.6666 7.65908 21.6666 6.82501C21.6666 5.99093 20.9353 5.31652 20.031 5.31652L20.0258 5.31413Z"
        fill="#8000FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_15107_8448">
        <rect
          width="21.3333"
          height="21.3333"
          fill="white"
          transform="translate(0.333252 0.333496)"
        />
      </clipPath>
    </defs>
  </svg>
);
