export const CategoryNewArrivals = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="30"
    viewBox="0 0 40 30"
    fill="none"
  >
    <g clipPath="url(#clip0_13561_3476)">
      <path
        d="M35.0591 4.69873H4.93858C2.65226 4.69873 0.798828 6.5507 0.798828 8.83521V21.1697C0.798828 23.4542 2.65226 25.3061 4.93858 25.3061H35.0591C37.3454 25.3061 39.1988 23.4542 39.1988 21.1697V8.83521C39.1988 6.5507 37.3454 4.69873 35.0591 4.69873Z"
        fill="#DABDFF"
      />
      <path
        d="M28.9845 0.600098H11.0205C7.52584 0.600098 4.69287 3.43082 4.69287 6.9227V23.0775C4.69287 26.5694 7.52584 29.4001 11.0205 29.4001H28.9845C32.4791 29.4001 35.3121 26.5694 35.3121 23.0775V6.9227C35.3121 3.43082 32.4791 0.600098 28.9845 0.600098Z"
        fill="#8000FF"
      />
      <path
        d="M20.913 8.93723L22.2536 11.6484C22.4038 11.9485 22.688 12.1575 23.0205 12.2057L26.018 12.6397C26.8546 12.7629 27.187 13.7863 26.5811 14.3757L24.4147 16.4868C24.1734 16.7226 24.0661 17.0602 24.1197 17.387L24.6292 20.3661C24.774 21.1967 23.8999 21.8343 23.1492 21.4378L20.468 20.0286C20.173 19.8732 19.8138 19.8732 19.5188 20.0286L16.8376 21.4378C16.0869 21.8289 15.2128 21.1967 15.3576 20.3661L15.867 17.387C15.926 17.0548 15.8134 16.7172 15.5721 16.4868L13.4057 14.3757C12.7998 13.7863 13.1322 12.7576 13.9688 12.6397L16.9663 12.2057C17.2988 12.1575 17.583 11.9485 17.7332 11.6484L19.0737 8.93723C19.4491 8.18173 20.527 8.18173 20.9023 8.93723H20.913Z"
        fill="#DABDFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_13561_3476">
        <rect
          width="38.4"
          height="28.8"
          fill="white"
          transform="translate(0.799805 0.600098)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const CategoryNewArrivalsActive = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
  >
    <g clipPath="url(#clip0_15107_8281)">
      <path
        d="M19.3667 2.27734H2.63312C1.36294 2.27734 0.333252 3.30621 0.333252 4.57539V11.4279C0.333252 12.697 1.36294 13.7259 2.63312 13.7259H19.3667C20.6369 13.7259 21.6666 12.697 21.6666 11.4279V4.57539C21.6666 3.30621 20.6369 2.27734 19.3667 2.27734Z"
        fill="#8000FF"
      />
      <path
        d="M15.9914 0H6.01143C4.06996 0 2.49609 1.57263 2.49609 3.51256V12.4874C2.49609 14.4274 4.06996 16 6.01143 16H15.9914C17.9329 16 19.5068 14.4274 19.5068 12.4874V3.51256C19.5068 1.57263 17.9329 0 15.9914 0Z"
        fill="#6600CC"
      />
      <path
        d="M11.508 4.63169L12.2528 6.13792C12.3362 6.30462 12.4941 6.42071 12.6788 6.4475L14.3441 6.68862C14.8088 6.75708 14.9936 7.32564 14.6569 7.65308L13.4534 8.82592C13.3193 8.95689 13.2597 9.14443 13.2895 9.32601L13.5725 10.9811C13.653 11.4425 13.1674 11.7967 12.7503 11.5764L11.2607 10.7935C11.0969 10.7072 10.8973 10.7072 10.7334 10.7935L9.24389 11.5764C8.82681 11.7937 8.34122 11.4425 8.42166 10.9811L8.70467 9.32601C8.73744 9.14145 8.67488 8.95392 8.54082 8.82592L7.33726 7.65308C7.00063 7.32564 7.18533 6.7541 7.65007 6.68862L9.31539 6.4475C9.50009 6.42071 9.65798 6.30462 9.7414 6.13792L10.4862 4.63169C10.6947 4.21196 11.2935 4.21196 11.502 4.63169H11.508Z"
        fill="#DABDFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_15107_8281">
        <rect
          width="21.3333"
          height="16"
          fill="white"
          transform="translate(0.333252)"
        />
      </clipPath>
    </defs>
  </svg>
);
