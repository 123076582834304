export const CategoryPlays = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="32"
    viewBox="0 0 36 32"
    fill="none"
  >
    <g clipPath="url(#clip0_13561_3398)">
      <path
        d="M22.6887 31.1703C16.7311 30.7616 12.1602 25.7336 12.3505 19.7936L12.7059 9.35867C12.786 7.05144 14.7685 5.26744 17.0865 5.42192L31.7853 6.42853C34.1033 6.588 35.8255 8.62614 35.5852 10.9234L34.4988 21.3085C33.868 27.2186 28.6513 31.5789 22.6937 31.1703H22.6887Z"
        fill="#AAFFD4"
      />
      <path
        d="M16.8135 13.9386C16.9136 12.4935 18.1702 11.4072 19.6221 11.5018C21.0739 11.5965 22.1653 12.8523 22.0702 14.2974L16.8185 13.9386H16.8135Z"
        fill="#13BD68"
      />
      <path
        d="M25.6196 14.5417C25.7198 13.0965 26.9764 12.0102 28.4282 12.1049C29.8801 12.2045 30.9715 13.4553 30.8763 14.9005L25.6246 14.5417H25.6196Z"
        fill="#13BD68"
      />
      <path
        d="M23.0269 26.2021C20.4336 26.0227 18.386 24.0742 17.9955 21.6225C17.8954 20.9896 18.406 20.4215 19.0518 20.4664L27.758 21.0643C28.3988 21.1092 28.8293 21.7371 28.6441 22.35C27.9182 24.727 25.6252 26.3765 23.0319 26.1971L23.0269 26.2021Z"
        fill="#13BD68"
      />
      <path
        d="M17.7365 26.5211C12.0192 28.2453 5.95642 25.1607 4.01393 19.5396L0.619593 9.65786C-0.131367 7.47022 1.09019 5.10318 3.31304 4.43045L17.4111 0.174769C19.6339 -0.492984 21.9719 0.802656 22.5626 3.03514L25.2511 13.1262C26.768 18.8768 23.4488 24.7919 17.7315 26.5161L17.7365 26.5211Z"
        fill="#13BD68"
      />
      <path
        d="M6.09723 12.4834C5.67669 11.098 6.46269 9.63297 7.85447 9.21438C9.24625 8.79579 10.7181 9.57815 11.1387 10.9635L6.09723 12.4834Z"
        fill="#AAFFD4"
      />
      <path
        d="M14.5489 9.93162C14.1283 8.54628 14.9144 7.08121 16.3061 6.66262C17.6979 6.24403 19.1698 7.0264 19.5903 8.41173L14.5489 9.93162Z"
        fill="#AAFFD4"
      />
      <path
        d="M16.2847 21.7522C13.7965 22.5046 11.1882 21.4033 9.94659 19.2506C9.62618 18.6925 9.90153 17.9849 10.5223 17.8005L18.873 15.2839C19.4888 15.0996 20.1146 15.5331 20.1596 16.1759C20.3299 18.6526 18.7729 21.0047 16.2847 21.7572V21.7522Z"
        fill="#AAFFD4"
      />
    </g>
    <defs>
      <clipPath id="clip0_13561_3398">
        <rect
          width="35.2"
          height="31.2"
          fill="white"
          transform="translate(0.399902)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const CategoryPlaysActive = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
  >
    <g clipPath="url(#clip0_15127_62037)">
      <path
        d="M15.1255 21.1132C11.1537 20.8408 8.10648 17.4887 8.23331 13.5287L8.47028 6.57212C8.52368 5.03397 9.84537 3.84464 11.3907 3.94762L21.1899 4.6187C22.7352 4.72501 23.8833 6.08377 23.7231 7.61528L22.9989 14.5386C22.5783 18.4787 19.1005 21.3856 15.1288 21.1132H15.1255Z"
        fill="#6600CC"
      />
      <path
        d="M11.2107 9.62495C11.2774 8.66152 12.1152 7.93729 13.0831 8.00041C14.051 8.06353 14.7786 8.90072 14.7152 9.86414L11.214 9.62495H11.2107Z"
        fill="#F3E9FF"
      />
      <path
        d="M17.0813 10.0268C17.1481 9.06338 17.9858 8.33915 18.9537 8.40227C19.9216 8.46871 20.6492 9.30257 20.5858 10.266L17.0846 10.0268H17.0813Z"
        fill="#F3E9FF"
      />
      <path
        d="M15.3527 17.8006C13.6238 17.681 12.2587 16.382 11.9984 14.7475C11.9316 14.3256 12.2721 13.9469 12.7026 13.9768L18.5067 14.3754C18.9339 14.4053 19.2209 14.8239 19.0975 15.2325C18.6135 16.8172 17.0849 17.9168 15.356 17.7972L15.3527 17.8006Z"
        fill="#F3E9FF"
      />
      <path
        d="M11.8246 18.0139C8.0131 19.1634 3.97127 17.107 2.67628 13.3596L0.413387 6.77174C-0.0872526 5.31331 0.727122 3.73529 2.20902 3.2868L11.6077 0.449683C13.0896 0.0045149 14.6483 0.868275 15.0421 2.3566L16.8344 9.08396C17.8457 12.9177 15.6328 16.8611 11.8213 18.0106L11.8246 18.0139Z"
        fill="#8000FF"
      />
      <path
        d="M4.06482 8.65542C3.78446 7.73186 4.30846 6.75515 5.23632 6.47609C6.16417 6.19703 7.14542 6.71861 7.42578 7.64216L4.06482 8.65542Z"
        fill="#F3E9FF"
      />
      <path
        d="M9.69861 6.95425C9.41825 6.03069 9.94225 5.05398 10.8701 4.77492C11.798 4.49586 12.7792 5.01743 13.0596 5.94099L9.69861 6.95425Z"
        fill="#F3E9FF"
      />
      <path
        d="M10.8569 14.8348C9.19808 15.3364 7.45919 14.6022 6.63146 13.1671C6.41786 12.795 6.60143 12.3232 7.01529 12.2003L12.5824 10.5226C12.9929 10.3997 13.4101 10.6887 13.4402 11.1173C13.5536 12.7684 12.5157 14.3365 10.8569 14.8381V14.8348Z"
        fill="#F3E9FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_15127_62037">
        <rect
          width="23.4667"
          height="20.8"
          fill="white"
          transform="translate(0.266602 0.333496)"
        />
      </clipPath>
    </defs>
  </svg>
);
