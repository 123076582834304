export const CategoryDiscount = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
  >
    <g clipPath="url(#clip0_13561_11894)">
      <path
        d="M18.2392 0.915918L21.0527 4.64682C21.5021 5.24103 22.2103 5.58198 22.9577 5.56249L27.6371 5.43099C29.1709 5.38715 30.3139 6.81912 29.9329 8.29979L28.7655 12.8197C28.5798 13.5406 28.7557 14.3053 29.2344 14.8751L32.253 18.4404C33.2446 19.6094 32.8343 21.3969 31.4324 22.0203L27.1584 23.9247C26.4746 24.2267 25.9861 24.8453 25.8396 25.5759L24.9262 30.1543C24.6282 31.6544 22.9723 32.4483 21.6095 31.747L17.4528 29.6039C16.7884 29.263 16.002 29.263 15.3377 29.6039L11.1809 31.747C9.81814 32.4483 8.16227 31.6544 7.86431 30.1543L6.95089 25.5759C6.80435 24.8453 6.31589 24.2316 5.63205 23.9247L1.35804 22.0203C-0.0389537 21.3969 -0.449259 19.6094 0.537428 18.4404L3.5561 14.8751C4.03968 14.3053 4.21552 13.5406 4.02502 12.8197L2.85761 8.29979C2.47661 6.81912 3.6196 5.38715 5.15336 5.43099L9.8328 5.56249C10.5801 5.58198 11.2884 5.24103 11.7378 4.64682L14.5611 0.915918C15.4843 -0.306608 17.3209 -0.306608 18.2441 0.915918H18.2392Z"
        fill="#FFBC00"
      />
      <path
        d="M20.1602 10.9171L10.3338 20.7154C9.69666 21.3507 9.69666 22.3807 10.3338 23.016C10.9709 23.6513 12.0039 23.6513 12.641 23.016L22.4674 13.2177C23.1045 12.5824 23.1045 11.5524 22.4674 10.9171C21.8303 10.2818 20.7973 10.2818 20.1602 10.9171Z"
        fill="#FFF8E5"
      />
      <path
        d="M11.5522 14.5975C13.0279 14.5975 14.2241 13.4047 14.2241 11.9333C14.2241 10.4619 13.0279 9.26904 11.5522 9.26904C10.0766 9.26904 8.88037 10.4619 8.88037 11.9333C8.88037 13.4047 10.0766 14.5975 11.5522 14.5975Z"
        fill="#FFF8E5"
      />
      <path
        d="M21.2476 24.266C22.7232 24.266 23.9194 23.0731 23.9194 21.6017C23.9194 20.1303 22.7232 18.9375 21.2476 18.9375C19.7719 18.9375 18.5757 20.1303 18.5757 21.6017C18.5757 23.0731 19.7719 24.266 21.2476 24.266Z"
        fill="#FFF8E5"
      />
    </g>
    <defs>
      <clipPath id="clip0_13561_11894">
        <rect width="32.8" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CategoryDiscountActive = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Layer_1" clipPath="url(#clip0_15107_261)">
      <path
        id="Vector"
        d="M12.1961 0.943783L14.0261 3.43105C14.3183 3.82719 14.779 4.05449 15.2651 4.0415L18.3086 3.95383C19.3062 3.9246 20.0496 4.87925 19.8018 5.86636L19.0425 8.87965C18.9218 9.36022 19.0361 9.87001 19.3475 10.2499L21.3108 12.6268C21.9558 13.4061 21.6889 14.5978 20.7771 15.0134L17.9973 16.283C17.5525 16.4843 17.2348 16.8967 17.1395 17.3838L16.5454 20.436C16.3516 21.4361 15.2746 21.9654 14.3882 21.4978L11.6846 20.0691C11.2526 19.8418 10.7411 19.8418 10.309 20.0691L7.60541 21.4978C6.71904 21.9654 5.64205 21.4361 5.44825 20.436L4.85416 17.3838C4.75885 16.8967 4.44115 16.4876 3.99638 16.283L1.21653 15.0134C0.307918 14.5978 0.041053 13.4061 0.6828 12.6268L2.64617 10.2499C2.96069 9.87001 3.07506 9.36022 2.95115 8.87965L2.19186 5.86636C1.94406 4.87925 2.68747 3.9246 3.68503 3.95383L6.72857 4.0415C7.21464 4.05449 7.67531 3.82719 7.96759 3.43105L9.80387 0.943783C10.4043 0.128765 11.5989 0.128765 12.1993 0.943783H12.1961Z"
        fill="#8000FF"
      />
      <g id="Group">
        <path
          id="Vector_2"
          d="M13.4459 7.61106L7.05476 14.1433C6.64037 14.5668 6.64037 15.2535 7.05476 15.677C7.46915 16.1006 8.141 16.1006 8.55539 15.677L14.9466 9.14482C15.3609 8.72128 15.3609 8.0346 14.9466 7.61106C14.5322 7.18753 13.8603 7.18753 13.4459 7.61106Z"
          fill="#FFF8E5"
        />
        <path
          id="Vector_3"
          d="M7.84693 10.0645C8.80669 10.0645 9.58473 9.2693 9.58473 8.28836C9.58473 7.30742 8.80669 6.51221 7.84693 6.51221C6.88717 6.51221 6.10913 7.30742 6.10913 8.28836C6.10913 9.2693 6.88717 10.0645 7.84693 10.0645Z"
          fill="#FFF8E5"
        />
        <path
          id="Vector_4"
          d="M14.1531 16.5098C15.1128 16.5098 15.8909 15.7146 15.8909 14.7337C15.8909 13.7527 15.1128 12.9575 14.1531 12.9575C13.1933 12.9575 12.4153 13.7527 12.4153 14.7337C12.4153 15.7146 13.1933 16.5098 14.1531 16.5098Z"
          fill="#FFF8E5"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_15107_261">
        <rect
          width="21.3333"
          height="21.3333"
          fill="white"
          transform="translate(0.333252 0.333496)"
        />
      </clipPath>
    </defs>
  </svg>
);
