export const CategoryTopShows = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
  >
    <path
      d="M13.7391 9.04593C13.7391 9.04593 15.923 3.67706 12.5253 0.423568C12.2569 1.36874 11.789 2.24755 11.1521 3.00261C10.5152 3.75767 9.72365 4.37199 8.82912 4.80544C6.8335 5.90103 2.96321 8.49538 2.23413 11.9826C1.89607 13.4677 1.98637 15.0163 2.49486 16.4536C3.00334 17.8908 3.90976 19.1597 5.11187 20.1169C5.30727 19.4554 5.63484 18.8389 6.07539 18.3037C6.51594 17.7684 7.0606 17.325 7.67746 16.9996C8.19246 16.759 8.65364 16.4199 9.0341 16.002C9.41456 15.5842 9.70668 15.0959 9.89342 14.5658C10.9158 15.4152 11.6967 16.5113 12.1597 17.7468C12.6228 18.9823 12.7521 20.315 12.5351 21.6145L12.5314 21.631C13.9306 21.3272 15.2171 20.6484 16.2486 19.6695C17.2801 18.6907 18.0164 17.4501 18.376 16.0849C19.3712 12.8748 18.5259 8.04317 17.23 6.24744C16.3271 7.45731 15.1256 8.4205 13.7391 9.04593Z"
      fill="#FFC37C"
    />
  </svg>
);

export const CategoryTopShowsActive = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M13.1712 8.62026C13.1712 8.62026 15.289 3.41408 11.9943 0.259173C11.734 1.1757 11.2803 2.02788 10.6627 2.76006C10.0451 3.49224 9.2775 4.08795 8.41008 4.50827C6.47493 5.57065 2.72192 8.08639 2.01494 11.4679C1.68712 12.9081 1.77469 14.4097 2.26776 15.8034C2.76083 17.1971 3.63979 18.4275 4.80547 19.3557C4.99495 18.7143 5.31259 18.1165 5.73979 17.5974C6.16699 17.0784 6.69515 16.6485 7.29332 16.3329C7.79271 16.0996 8.23992 15.7708 8.60885 15.3656C8.97778 14.9604 9.26105 14.4869 9.44213 13.9728C10.4335 14.7965 11.1908 15.8594 11.6398 17.0575C12.0888 18.2556 12.2142 19.5478 12.0037 20.8079L12.0002 20.8239C13.357 20.5294 14.6045 19.8711 15.6048 18.9219C16.605 17.9728 17.319 16.7697 17.6677 15.446C18.6327 12.3331 17.813 7.64788 16.5564 5.90656C15.6809 7.07977 14.5157 8.01378 13.1712 8.62026Z"
      fill="#8000FF"
    />
  </svg>
);
