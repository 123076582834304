export const CategoryComedy = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="36"
    viewBox="0 0 46 36"
    fill="none"
  >
    <g clipPath="url(#clip0_13561_6532)">
      <path
        d="M17.8096 35.0269C27.3424 35.0269 35.0703 27.4025 35.0703 17.9973C35.0703 8.59216 27.3424 0.967773 17.8096 0.967773C8.27673 0.967773 0.548828 8.59216 0.548828 17.9973C0.548828 27.4025 8.27673 35.0269 17.8096 35.0269Z"
        fill="#FFAF66"
      />
      <path
        d="M16.0688 30.1847C11.6054 29.5618 8.31683 25.9653 7.97644 21.7079C7.8886 20.6408 8.82191 19.7688 9.89248 19.915L24.8858 22.0004C25.9564 22.152 26.6097 23.2462 26.2254 24.2428C24.7047 28.2348 20.5322 30.8022 16.0688 30.1793V30.1847Z"
        fill="#F5F5F5"
      />
      <path
        d="M9.89254 14.2873C9.54118 14.2385 9.23373 13.9785 9.14589 13.6156C9.03609 13.1444 9.33256 12.6677 9.81019 12.5594L12.8572 11.8553L10.1231 10.2845C9.6949 10.0407 9.55216 9.49908 9.79921 9.08201C10.0463 8.65952 10.5898 8.51869 11.018 8.76243L15.1356 11.1295C15.5473 11.3624 15.7614 11.8174 15.6955 12.2778C15.6242 12.7382 15.2838 13.1065 14.8226 13.2148L10.2219 14.2765C10.1121 14.3035 10.0023 14.309 9.89254 14.2927V14.2873Z"
        fill="#F5F5F5"
      />
      <path
        d="M26.4619 16.5941C26.3576 16.5779 26.2478 16.5454 26.149 16.4912L21.9107 14.1513C21.5154 13.9346 21.3013 13.5067 21.3562 13.0626C21.4165 12.6184 21.735 12.2609 22.1742 12.1526L26.9176 10.9718C27.3952 10.8526 27.8783 11.1397 27.9991 11.6109C28.1199 12.0822 27.8289 12.5588 27.3513 12.678L24.2824 13.4417L27.0109 14.9475C27.4391 15.1858 27.5929 15.7221 27.3568 16.1446C27.1701 16.4696 26.8078 16.6375 26.4509 16.5887L26.4619 16.5941Z"
        fill="#FFF2CC"
      />
      <path
        d="M32.6446 28.5547C39.7154 28.5547 45.4474 22.8994 45.4474 15.9233C45.4474 8.94723 39.7154 3.29199 32.6446 3.29199C25.5738 3.29199 19.8418 8.94723 19.8418 15.9233C19.8418 22.8994 25.5738 28.5547 32.6446 28.5547Z"
        fill="#FFD766"
      />
      <path
        d="M36.2449 24.3294C33.165 25.6131 29.6843 24.5623 27.8177 21.9841C27.351 21.3395 27.6036 20.4349 28.3447 20.1208L38.688 15.8038C39.4292 15.4951 40.2637 15.9446 40.4064 16.7246C40.9829 19.8391 39.3194 23.0403 36.2449 24.324V24.3294Z"
        fill="white"
      />
      <path
        d="M26.1768 16.5412C25.9352 16.6441 25.6388 16.5954 25.4411 16.395C25.1886 16.135 25.1941 15.7233 25.4576 15.4741L27.1211 13.8817L24.7713 13.9088C24.4035 13.9088 24.1071 13.6271 24.1016 13.2642C24.1016 12.9013 24.387 12.6088 24.7549 12.6034L28.2905 12.5601C28.6418 12.5601 28.9548 12.7605 29.0865 13.08C29.2183 13.3996 29.1414 13.7625 28.8944 14.0063L26.3799 16.4112C26.3195 16.4708 26.2482 16.5141 26.1768 16.5466V16.5412Z"
        fill="white"
      />
      <path
        d="M37.6028 11.7746C37.5314 11.8071 37.4491 11.8234 37.3667 11.8234L33.7707 11.9317C33.4358 11.9425 33.1339 11.753 32.9966 11.4496C32.8649 11.1463 32.9307 10.7996 33.1668 10.5613L35.7252 8.02638C35.9832 7.77181 36.4005 7.77181 36.664 8.02638C36.922 8.28096 36.922 8.69261 36.664 8.95261L35.006 10.5938L37.3228 10.5234C37.6906 10.5126 37.9926 10.7942 38.0036 11.1571C38.0145 11.4334 37.8443 11.6771 37.5973 11.78L37.6028 11.7746Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_13561_6532">
        <rect
          width="44.9032"
          height="34.0645"
          fill="white"
          transform="translate(0.548828 0.967773)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const CategoryComedyActive = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <g clipPath="url(#clip0_15107_8178)">
      <path
        d="M10.3333 20.3335C15.8561 20.3335 20.3333 15.8563 20.3333 10.3335C20.3333 4.81065 15.8561 0.333496 10.3333 0.333496C4.8104 0.333496 0.333252 4.81065 0.333252 10.3335C0.333252 15.8563 4.8104 20.3335 10.3333 20.3335Z"
        fill="#8000FF"
      />
      <path
        d="M9.62446 17.6104C6.70811 17.2101 4.55941 14.8987 4.337 12.1626C4.27961 11.4768 4.88942 10.9163 5.58892 11.0103L15.3854 12.3505C16.0849 12.448 16.5118 13.1512 16.2607 13.7917C15.267 16.3572 12.5408 18.0073 9.62446 17.6069V17.6104Z"
        fill="#F5F5F5"
      />
      <path
        d="M5.88357 8.15421C5.67487 8.12559 5.49226 7.97291 5.44008 7.75981C5.37487 7.48309 5.55095 7.20319 5.83465 7.13958L7.64445 6.7261L6.02052 5.8037C5.76617 5.66057 5.68139 5.34251 5.82813 5.0976C5.97487 4.84951 6.2977 4.76681 6.55205 4.90994L8.99773 6.29989C9.24229 6.43666 9.36947 6.70383 9.33034 6.97419C9.28795 7.24454 9.08577 7.46083 8.81186 7.52444L6.07922 8.14785C6.014 8.16375 5.94878 8.16694 5.88357 8.15739V8.15421Z"
        fill="#F5F5F5"
      />
      <path
        d="M15.7249 9.50925C15.663 9.49971 15.5978 9.48063 15.5391 9.44882L13.0217 8.07478C12.7869 7.94755 12.6597 7.69628 12.6923 7.43546C12.7282 7.17465 12.9173 6.96472 13.1782 6.90111L15.9956 6.20773C16.2793 6.13775 16.5663 6.30633 16.638 6.58305C16.7097 6.85976 16.5369 7.13966 16.2532 7.20964L14.4304 7.65811L16.051 8.54233C16.3054 8.68228 16.3967 8.99717 16.2565 9.24526C16.1456 9.4361 15.9304 9.5347 15.7184 9.50607L15.7249 9.50925Z"
        fill="#FFF2CC"
      />
    </g>
    <defs>
      <clipPath id="clip0_15107_8178">
        <rect
          width="21.3333"
          height="21.3333"
          fill="white"
          transform="translate(0.333252 0.333496)"
        />
      </clipPath>
    </defs>
  </svg>
);
