export const CategoryFallback = (
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      d="M17.6579 17.1875L1.8341 1.24886C1.67596 1.08958 1.4608 1 1.23635 1C0.705348 1 0.306817 1.48538 0.410163 2.00623L3.42239 17.1875C6.63426 18.4016 15.8225 18.1318 17.6579 17.1875Z"
      fill="#FFBC00"
    />
    <path
      opacity="0.5"
      d="M3.34212 17.1875L19.2454 1.2371C19.3967 1.08531 19.6023 1 19.8166 1C20.322 1 20.7029 1.45941 20.6093 1.95604L17.7382 17.1875C14.6869 18.4016 5.08571 18.1318 3.34212 17.1875Z"
      fill="#FFBC00"
    />
    <ellipse cx="10.5" cy="17.1053" rx="7.15789" ry="0.894737" fill="#FFD766" />
    <path
      d="M10.2986 0.475974C10.3739 0.297989 10.6261 0.297989 10.7015 0.475974L11.3847 2.09031C11.4163 2.16495 11.4866 2.21601 11.5673 2.22299L13.3138 2.37393C13.5063 2.39058 13.5843 2.63047 13.4383 2.75711L12.1141 3.90578C12.0529 3.95889 12.026 4.0415 12.0443 4.12045L12.4405 5.82808C12.4841 6.01635 12.2801 6.16461 12.1145 6.0649L10.6129 5.16048C10.5434 5.11866 10.4566 5.11866 10.3871 5.16048L8.8855 6.0649C8.71994 6.16461 8.51587 6.01635 8.55955 5.82808L8.95567 4.12045C8.97399 4.0415 8.94714 3.95889 8.88592 3.90578L7.56173 2.75711C7.41573 2.63047 7.49368 2.39058 7.68623 2.37393L9.43269 2.22299C9.51344 2.21601 9.58371 2.16495 9.6153 2.09031L10.2986 0.475974Z"
      fill="#FFCB39"
    />
  </svg>
);

export const CategoryFallbackActive = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="22"
    viewBox="0 0 25 22"
    fill="none"
  >
    <path
      opacity="0.5"
      d="M20.6784 20.7032L1.92425 1.81301C1.73683 1.62424 1.48182 1.51807 1.21581 1.51807C0.586474 1.51807 0.114141 2.09332 0.236625 2.71063L3.80667 20.7032C7.61334 22.1421 18.5031 21.8224 20.6784 20.7032Z"
      fill="#6600CC"
    />
    <path
      opacity="0.5"
      d="M3.71153 20.7032L22.5599 1.79907C22.7392 1.61918 22.9828 1.51807 23.2369 1.51807C23.8358 1.51807 24.2873 2.06256 24.1763 2.65115L20.7736 20.7032C17.1572 22.1421 5.77801 21.8224 3.71153 20.7032Z"
      fill="#6600CC"
    />
    <ellipse
      cx="12.1951"
      cy="20.6058"
      rx="8.48343"
      ry="1.06043"
      fill="#6600CC"
    />
    <path
      d="M11.9563 0.897614C12.0455 0.686668 12.3445 0.686668 12.4338 0.897614L13.2436 2.8109C13.281 2.89936 13.3643 2.95987 13.46 2.96815L15.5299 3.14705C15.7581 3.16677 15.8505 3.45109 15.6774 3.60119L14.108 4.96257C14.0354 5.02551 14.0036 5.12342 14.0253 5.21699L14.4948 7.24085C14.5466 7.46398 14.3047 7.6397 14.1085 7.52152L12.3288 6.44961C12.2465 6.40005 12.1435 6.40005 12.0613 6.44961L10.2815 7.52152C10.0853 7.6397 9.84346 7.46398 9.89523 7.24085L10.3647 5.21699C10.3864 5.12342 10.3546 5.02551 10.282 4.96257L8.71263 3.60119C8.5396 3.45109 8.63198 3.16677 8.86019 3.14705L10.9301 2.96815C11.0258 2.95987 11.1091 2.89936 11.1465 2.8109L11.9563 0.897614Z"
      fill="#5900B1"
    />
  </svg>
);
