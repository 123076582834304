const PracticalInfoCalendar = ({ color = '#444444' }: { color?: string }) => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6667 2.6665H2.33333C1.59695 2.6665 1 3.26346 1 3.99984V13.3332C1 14.0696 1.59695 14.6665 2.33333 14.6665H11.6667C12.403 14.6665 13 14.0696 13 13.3332V3.99984C13 3.26346 12.403 2.6665 11.6667 2.6665Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.66699 1.33325V3.99992"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.33301 1.33325V3.99992"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 6.6665H13"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PracticalInfoCalendar;
