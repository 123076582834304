export const CategoryKids = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="29"
    viewBox="0 0 31 29"
    fill="none"
  >
    <g clipPath="url(#clip0_13561_10096)">
      <path
        d="M7.78525 11.2032C10.64 11.2032 12.9543 8.82962 12.9543 5.90165C12.9543 2.97369 10.64 0.600098 7.78525 0.600098C4.93047 0.600098 2.61621 2.97369 2.61621 5.90165C2.61621 8.82962 4.93047 11.2032 7.78525 11.2032Z"
        fill="#FF007A"
      />
      <path
        d="M23.1788 11.2032C26.0336 11.2032 28.3479 8.82962 28.3479 5.90165C28.3479 2.97369 26.0336 0.600098 23.1788 0.600098C20.324 0.600098 18.0098 2.97369 18.0098 5.90165C18.0098 8.82962 20.324 11.2032 23.1788 11.2032Z"
        fill="#FF007A"
      />
      <path
        d="M15.3979 5.52148C9.35227 5.52148 4.17871 9.68766 4.17871 20.1332L15.3979 24.3967L26.6171 20.1332C26.6171 9.68766 21.4435 5.52148 15.3979 5.52148Z"
        fill="#FFB0D6"
      />
      <path
        d="M15.3933 28.6001C7.12014 28.6001 2.66952 25.9215 2.18605 25.6157C0.654317 24.6425 0.143738 22.5107 1.04742 20.8563C1.9511 19.2019 3.92563 18.6504 5.46189 19.6282C5.46189 19.6282 8.88683 21.6488 15.3933 21.6488C21.8998 21.6488 25.3248 19.6282 25.3564 19.6097C26.8881 18.6365 28.8491 19.1973 29.7528 20.847C30.6565 22.5015 30.1323 24.6425 28.5961 25.6157C28.1171 25.9215 23.662 28.6001 15.3888 28.6001H15.3933Z"
        fill="#FF007A"
      />
    </g>
    <defs>
      <clipPath id="clip0_13561_10096">
        <rect
          width="29.6"
          height="28"
          fill="white"
          transform="translate(0.600098 0.600098)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const CategoryKidsActive = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <g clipPath="url(#clip0_15107_8207)">
      <path
        d="M5.51132 8.41206C7.56883 8.41206 9.23676 6.60361 9.23676 4.37278C9.23676 2.14194 7.56883 0.333496 5.51132 0.333496C3.45382 0.333496 1.78589 2.14194 1.78589 4.37278C1.78589 6.60361 3.45382 8.41206 5.51132 8.41206Z"
        fill="#6600CC"
      />
      <path
        d="M16.6061 8.41206C18.6636 8.41206 20.3315 6.60361 20.3315 4.37278C20.3315 2.14194 18.6636 0.333496 16.6061 0.333496C14.5486 0.333496 12.8806 2.14194 12.8806 4.37278C12.8806 6.60361 14.5486 8.41206 16.6061 8.41206Z"
        fill="#6600CC"
      />
      <path
        d="M10.9982 4.08252C6.64105 4.08252 2.91235 7.25675 2.91235 15.2153L10.9982 18.4636L19.0841 15.2153C19.0841 7.25675 15.3554 4.08252 10.9982 4.08252Z"
        fill="#8000FF"
      />
      <path
        d="M10.9952 21.6669C5.03252 21.6669 1.82486 19.6261 1.47642 19.3931C0.372465 18.6516 0.00448048 17.0274 0.655781 15.7669C1.30708 14.5064 2.73017 14.0862 3.83738 14.8312C3.83738 14.8312 6.30581 16.3707 10.9952 16.3707C15.6845 16.3707 18.153 14.8312 18.1758 14.8171C19.2797 14.0756 20.693 14.5029 21.3443 15.7598C21.9956 17.0203 21.6179 18.6516 20.5107 19.3931C20.1655 19.6261 16.9546 21.6669 10.9919 21.6669H10.9952Z"
        fill="#6600CC"
      />
    </g>
    <defs>
      <clipPath id="clip0_15107_8207">
        <rect
          width="21.3333"
          height="21.3333"
          fill="white"
          transform="translate(0.333252 0.333496)"
        />
      </clipPath>
    </defs>
  </svg>
);
