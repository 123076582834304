export const CategoryChristmas = (
  <svg
    width="40"
    height="30"
    viewBox="0 0 19 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="7" y="17" width="5" height="5" rx="0.5" fill="#E6A900" />
    <path
      d="M9.15545 1.32763L4.59928 5.66009C4.32682 5.91916 4.42821 6.37226 4.79138 6.46953C8.21317 7.386 12.0265 6.93758 14.1645 6.44708C14.5393 6.36111 14.6524 5.89941 14.3738 5.63447L9.84455 1.32763C9.65152 1.14408 9.34848 1.14408 9.15545 1.32763Z"
      fill="#10A35A"
      stroke="#10A35A"
      strokeLinejoin="round"
    />
    <path
      d="M9.13859 4.37803L2.52429 11.2965C2.27616 11.5561 2.37497 11.9837 2.71699 12.0931C7.68291 13.6807 13.3452 12.8607 16.241 12.0709C16.5961 11.974 16.7056 11.5369 16.4512 11.2709L9.86141 4.37803C9.66449 4.17206 9.33551 4.17206 9.13859 4.37803Z"
      fill="#10A35A"
      stroke="#10A35A"
      strokeLinejoin="round"
    />
    <path
      d="M9.14203 9.36709L1.53923 17.1636C1.28608 17.4232 1.38533 17.8565 1.73082 17.9665C7.4289 19.7813 13.9578 18.8328 17.2276 17.944C17.5861 17.8465 17.6951 17.4039 17.4358 17.138L9.85797 9.36709C9.66174 9.16586 9.33826 9.16586 9.14203 9.36709Z"
      fill="#10A35A"
      stroke="#10A35A"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 13.5C8 14 13 13.5 15.5 10"
      stroke="#7CE2AD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 7.5C7.5 8 12 6 13 4"
      stroke="#7CE2AD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="8" cy="7" r="1" fill="#FFD43E" />
    <circle cx="13" cy="12" r="1" fill="#FFD43E" />
    <circle cx="7" cy="15" r="1" fill="#FFD43E" />
  </svg>
);

export const CategoryChristmasActive = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <g clipPath="url(#clip0_15127_62009)">
      <rect
        x="8.71411"
        y="16.9048"
        width="4.7619"
        height="4.7619"
        rx="0.476191"
        fill="#5900B1"
      />
      <path
        d="M10.7671 1.97853L6.42792 6.10468C6.16844 6.35142 6.26499 6.78294 6.61087 6.87558C9.86972 7.74841 13.5015 7.32135 15.5377 6.8542C15.8946 6.77232 16.0023 6.33261 15.737 6.08028L11.4234 1.97853C11.2396 1.80372 10.951 1.80372 10.7671 1.97853Z"
        fill="#6600CC"
        stroke="#6600CC"
        strokeWidth="0.952381"
        strokeLinejoin="round"
      />
      <path
        d="M10.7508 4.88395L4.45147 11.473C4.21516 11.7202 4.30927 12.1275 4.63499 12.2316C9.36444 13.7437 14.7571 12.9627 17.515 12.2105C17.8532 12.1182 17.9574 11.7019 17.7152 11.4486L11.4392 4.88395C11.2517 4.68779 10.9383 4.68779 10.7508 4.88395Z"
        fill="#6600CC"
        stroke="#6600CC"
        strokeWidth="0.952381"
        strokeLinejoin="round"
      />
      <path
        d="M10.7543 9.63526L3.51355 17.0605C3.27246 17.3077 3.36698 17.7204 3.69602 17.8252C9.12276 19.5536 15.3407 18.6503 18.4549 17.8037C18.7963 17.7109 18.9001 17.2894 18.6531 17.0361L11.4362 9.63526C11.2493 9.44361 10.9412 9.44361 10.7543 9.63526Z"
        fill="#6600CC"
        stroke="#6600CC"
        strokeWidth="0.952381"
        strokeLinejoin="round"
      />
      <path
        d="M6.33325 13.5716C9.66659 14.0478 14.4285 13.5716 16.8094 10.2383"
        stroke="#8000FF"
        strokeWidth="0.952381"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.80957 7.85726C9.19052 8.33345 13.4762 6.42869 14.4286 4.52393"
        stroke="#8000FF"
        strokeWidth="0.952381"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="9.66649" cy="7.3806" r="0.952381" fill="#FFF8E5" />
      <circle cx="14.4287" cy="12.1433" r="0.952381" fill="#FFF8E5" />
      <circle cx="8.71434" cy="14.9997" r="0.952381" fill="#FFF8E5" />
    </g>
    <defs>
      <clipPath id="clip0_15127_62009">
        <rect
          width="21.3333"
          height="21.3333"
          fill="white"
          transform="translate(0.333252 0.333496)"
        />
      </clipPath>
    </defs>
  </svg>
);
